/* LoginPage.css */

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background: #ffffff;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background: #3983F8;
  height: 57px;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 20px;
}

.login-box {
  background: #ffffff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.logo {
  width: 200px;
  margin-bottom: 20px;
  object-fit: cover;
}

h2 {
  margin-bottom: 20px;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  height: 47px;
}


.input-email {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: bold;
  text-align: right;
}

.input-email::placeholder {
  text-align: right;
}

.input-group span {
  background: #f0f0f0;
  padding: 0 10px;
  font-size: 15px;
  font-weight: bold;
  color: #555555;
  border-left: 1px solid #ddd;
  display: flex;
  align-items: center;
  height: 100%;
}
.login-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: #9db8c9;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;
  cursor: not-allowed;
  height: 47px;
  transition: background 0.3s ease;
}

.login-button.active {
  background: #3983F8;
  cursor: pointer;
}

.login-button .spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.footer {
  background: #ffffff;
  text-align: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  color: rgba(0, 0, 0, 0.3);
  font-size: 15px;
  font-weight: bold;
}

.footer a {
  color: rgba(0, 0, 0, 0.3);
  text-decoration: none;
  margin: 0 5px;
}

.footer a:hover {
  text-decoration: underline;
}

.divider {
  margin: 0 5px;
}

@media (max-width: 768px) {
  .login-box {
    padding: 20px;
  }
  
  .logo {
    width: 80px;
  }
  
  h2 {
    font-size: 24px;
  }
  
  .input-group span, .input-email {
    font-size: 14px;
  }
}
