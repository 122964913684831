/* AnalyticsPage.css */

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background: #ffffff;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background: #3983F8;
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 35px;
}

.header .logoMain {
  height: 40px;
  width: 74px;
  object-fit: contain;
}

.header .profile-icon {
  color: #ffffff;
  font-size: 24px;
}

.result-date-wrapper {
  height: 84px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
}

.result-date-box {
  background: #F2F8FA;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  margin: 20px 10px 20px 35px;
  text-transform: uppercase;
}

.result-date-box .calendar-icon {
  margin-right: 10px;
}

.result-date-box.selected {
  border: 2px solid #3983F8;
  /* Add border when selected */
}

.results-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(385px, 1fr));
  gap: 25px;
  padding: 0px 35px;
}


.result-box {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #DCE5EA;
  height: 340px;
}

.result-box-header {
  height: 55px;
  border-bottom: 1px solid #DCE5EA;
  display: flex;
  align-items: center;
  padding-left: 25px;
  font-size: 14px;
  font-weight: bold;
}

.result-box-content {
  height: 285px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.result-box-content canvas {
  width: 90% !important;
  height: 90% !important;
}


@media (max-width: 768px) {
  .results-container {
    grid-template-columns: 1fr;
  }

  .result-box {
    margin-bottom: 25px;
  }
}

.experience-container {
  height: 285px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.experience-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: normal;
  /* Regular */
  display: flex;
  align-items: center;
  /* Align icon with text */
}

.experience-value {
  font-family: 'Montserrat', sans-serif;
  font-size: 33px;
  font-weight: 900;
  /* Black */
  margin-top: 5px;
  /* Adjust spacing between T1 and T2 as needed */
}

.info-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 12px;
  /* Adjust size as needed */
  height: 12px;
  /* Adjust size as needed */
  line-height: 12px;
  line-height: 20px;
  /* Make sure text is centered vertically */
  border-radius: 50%;
  background-color: #B4B9C4;
  color: white;
  /* Text color */
  font-size: 8px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  /* Use Montserrat if it's part of your font stack */
  margin-left: 5px;
  /* Space between text and icon */
}


.profile-menu {
  position: absolute;
  top: 50px;
  /* Adjust based on the height of your header */
  right: 0;
  background-color: white;
  color: black;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;
  /* Ensure it appears above other elements */
  width: 200px;
  /* Adjust the width as needed */
}

.profile-menu a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
}

.profile-menu a:hover {
  background-color: #f0f0f0;
}

/* Responsive styles */
@media (max-width: 600px) {
  .profile-menu {
    width: 100%;
    right: 0;
    left: 0;
    top: 50px;
    /* Ensure it stays below the header */
  }
}

.picker-container {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-left: 8px;
}

.picker {
  width: 140px;
  padding: 10px;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  background: #fafafa;
  transition: border-color 0.3s;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23999' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 8px 10px;
}

.picker:focus {
  border-color: #007bff;
  outline: none;
}



.confirm-button {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  background-color: #3983F8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s;
}

.confirm-button:hover {
  background-color: #3973F8;
}

.no-data-info {
  color: #6c757d;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}